.fade-in{
animation: fadeIn 1.5s  ;
}
@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}